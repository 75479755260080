@font-face {
  font-family: "Menlo";
  src: local("Menlo"),
    url("../assets/fonts/Menlo-Regular.ttf") format("truetype");
  font-weight: normal;
}
* {
  text-rendering: optimizeLegibility;
  // box-shadow: none !important;
}
#root{
  background-color: black;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Menlo", "Roboto" !important;
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

.icon {
  width: 44px;
  height: 44px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.smIcon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.lgIcon {
  width: 70px;
  height: 70px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.block{
  margin-top: 60px;
}
.header{
  text-align: left;
  font-Size: 1.2rem;
  padding-top: 15px;
  font-weight: 700;
}
.card {
  border: 1px solid white;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
}
.vaultHeader {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  padding-top: 15px;
}
.vaultSubHeader{
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top:20px;
  margin-Bottom:15px;
}

.tooltip {
  padding: 0;
  margin-left: 10px;
  margin-top:-2px;
}

.labelLink {
  color: white;
  font-size: 16px; 
  font-weight: 600;
  text-decoration: underline;
}